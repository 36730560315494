@font-face {
  font-family: 'Cvale_Fidelidade';
  src:  url('fonts/Cvale_Fidelidade.eot?c54l84');
  src:  url('fonts/Cvale_Fidelidade.eot?c54l84#iefix') format('embedded-opentype'),
    url('fonts/Cvale_Fidelidade.ttf?c54l84') format('truetype'),
    url('fonts/Cvale_Fidelidade.woff?c54l84') format('woff'),
    url('fonts/Cvale_Fidelidade.svg?c54l84#Cvale_Fidelidade') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Cvale_Fidelidade' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-login_inativo:before {
  content: "\e91a";
}
.icon-sessao_finalizada:before {
  content: "\e919";
}
.icon-identificacao_usuario:before {
  content: "\e918";
}
.icon-cancelar:before {
  content: "\e900";
}
.icon-error:before {
  content: "\e901";
}
.icon-estorno:before {
  content: "\e902";
}
.icon-logo_fidelidade .path1:before {
  content: "\e903";
  color: rgb(18, 68, 144);
}
.icon-logo_fidelidade .path2:before {
  content: "\e904";
  margin-left: -1.6337890625em;
  color: rgb(18, 68, 144);
}
.icon-logo_fidelidade .path3:before {
  content: "\e905";
  margin-left: -1.6337890625em;
  color: rgb(61, 186, 199);
}
.icon-logo_fidelidade .path4:before {
  content: "\e906";
  margin-left: -1.6337890625em;
  color: rgb(18, 68, 144);
}
.icon-menu_alterar_senha:before {
  content: "\e907";
}
.icon-menu_credito:before {
  content: "\e908";
}
.icon-menu_dados_parceiro:before {
  content: "\e909";
}
.icon-menu_dados_usuario:before {
  content: "\e90a";
}
.icon-menu_hamburguer_aberto:before {
  content: "\e90b";
}
.icon-menu_recebimento:before {
  content: "\e90c";
}
.icon-menu_relatorio:before {
  content: "\e90d";
}
.icon-menu_sair:before {
  content: "\e90e";
}
.icon-processando:before {
  content: "\e90f";
}
.icon-qrCode:before {
  content: "\e910";
}
.icon-senha:before {
  content: "\e911";
}
.icon-senha_nao_visualizar:before {
  content: "\e912";
}
.icon-senha_visualizar:before {
  content: "\e913";
}
.icon-sucesso:before {
  content: "\e914";
}
.icon-usuario:before {
  content: "\e915";
}
.icon-visualizar:before {
  content: "\e916";
}
.icon-buscar:before {
  content: "\e917";
}
